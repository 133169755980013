<template>
    <div class="component-purchase-position-button">
        <div class="purchase-position-button">

            <MainButton
                v-if="showPurchasePositionButton"
                btn-type="button"
                color-mode="secondary"
                :btn-text="$t('purchasePosition')"
                :is-loading="loadingState"
                @handle-click="handleModal(true)"
            />

            <MainButton
                v-if="showToPayPositionButton"
                btn-type="button"
                color-mode="secondary"
                :btn-text="$t('toPayPosition')"
                :is-loading="loadingState"
                @handle-click="handleModal(true)"
            />

            <MainButton
                v-if="showPurchaseChatButton"
                btn-type="button"
                color-mode="secondary"
                :btn-text="$t('purchaseChat')"
                :is-loading="loadingState"
                @handle-click="handleModal(true)"
            />

            <MainButton
                v-if="showToPayChatButton"
                btn-type="button"
                color-mode="secondary"
                :btn-text="$t('toPayChat')"
                :is-loading="loadingState"
                @handle-click="handleModal(true)"
            />

            <p class="purchase-info mt-2" v-if="showBuyInfo && purchaseInfoText">
                <i>{{ purchaseInfoText }}</i>
            </p>

            <div
                class="modal fade"
                :id="modalId(false)"
                tabindex="-1"
                :aria-labelledby="modalId(false) + '--label'"
                aria-hidden="true"
            >
                <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div class="modal-content">
                        <div class="modal-header">
                            <div>
<!--                                <h1 class="modal-title fs-5" :id="modalId + '&#45;&#45;label'">{{ !isPositionPurchasedAndPaid ? $t('purchasePosition') : $t('purchaseChat') }}</h1>-->
                                <h1 class="modal-title fs-5" :id="modalId + '--label'">
                                    {{ $t('orderConfirmation') }}
                                </h1>
                            </div>

                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>

                        <div class="modal-body">
                            <div class="section-purchase purchase-position" v-if="!isPositionPurchasedAndPaid">
                                <p class="purchase-description">{{ $t("purchaseDescriptionPosition") }}</p>
                            </div>

                            <div class="section-purchase purchase-chat" v-else>
                                <p class="purchase-description">{{ $t("purchaseDescriptionChat") }}</p>
                            </div>

                            <div>
                                {{ $t('price')}}:
                                <strong v-if="!isPositionPurchasedAndPaid">{{ positionData.price }} EUR</strong>
                                <strong v-else>{{ positionData.price_consultation }} EUR</strong>
                            </div>

                            <hr>

                            <div>
                                <div class="input-container form-check">
                                    <input
                                        type="checkbox"
                                        class="form-check-input"
                                        id="checkAgreeWithPurchase"
                                        name="accept-purchase-consent"
                                        v-model="formData.acceptConsent"
                                    >
                                    <label class="form-check-label" for="checkAgreeWithPurchase">
                                        {{ $t('orderConfirmationConsent') }}
                                        <span class="attribute-required">*</span>
                                    </label>
                                    <div class="error-text">{{ showInputError('acceptConsent') }}</div>
                                </div>
                            </div>
                        </div>

<!--                        <div class="modal-body">-->
<!--                            <div class="position-title"><strong>{{ positionData.title }}</strong></div>-->
<!--                            <p class="position-description">{{ positionData.description }}</p>-->
<!--                        </div>-->

                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                                {{ $t('cancel') }}
                            </button>

                            <button type="button" class="btn btn-primary" @click="purchaseProduct">
                                {{ $t('confirm') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import MainButton from "../../elements/MainButton.vue";
import {mapGetters} from "vuex";
import { useVuelidate } from '@vuelidate/core'
import validation from "../../../functions/validation";

export default {
    name: "PurchasePositionButton",
    mixins: [ validation ],
    components: {
        MainButton
    },
    props: {
        positionData: {
            type: Object,
            default() {
                return {}
            }
        },
        /**
         * Should information about the purchase be displayed under the button
         */
        showBuyInfo: {
            type: Boolean,
            default: false
        },
        loadingState: {
            type: Boolean,
            default: false
        },
    },
    setup() {
        return {
            v$: useVuelidate()
        }
    },
    data() {
        return {
            formData: {
                acceptConsent: false,
            }
        }
    },
    methods: {
        /**
         * Handle modal
         * @param {boolean} showModal - true -> show modal, false -> hide modal
         */
        handleModal(showModal = true) {
            showModal
                ? window.bootstrap.Modal.getOrCreateInstance(this.modalId(true))?.show()
                : window.bootstrap.Modal.getOrCreateInstance(this.modalId(true))?.hide()
        },
        async purchaseProduct() {
            this.v$.$touch()
            if (this.v$.$invalid) return

            const productType = !this.isPositionPurchasedAndPaid ? 'position' : 'message'
            this.$emit('purchase-product', productType)
            this.handleModal(false)
        }
    },
    computed: {
        ...mapGetters({
            getWebSettingsByKey: 'web/getWebSettingsByKey'
        }),
        /**
         * Returns true if the position is purchased and paid
         * @returns {boolean}
         */
        isPositionPurchasedAndPaid() {
            return !!(
                this.positionData?.purchases?.purchased_position
                && this.positionData?.purchases?.paid_position
            )
        },
        /**
         * Modal ID
         */
        modalId() {
            const nameId = 'purchaseModal--' + this.positionData.id
            return (withHash = false) => withHash ? `#${nameId}` : nameId
        },
        showPurchasePositionButton() {
            return !this.positionData?.purchases?.purchased_position
                && !this.positionData?.purchases?.paid_position
        },
        showToPayPositionButton() {
            return this.positionData?.purchases?.purchased_position
                && !this.positionData?.purchases?.paid_position
        },
        showPurchaseChatButton() {
            return this.positionData?.purchases?.paid_position
                && !this.positionData?.purchases?.purchased_consultation
                && !this.positionData?.purchases?.paid_consultation
        },
        showToPayChatButton() {
            return this.positionData?.purchases?.paid_position
                && this.positionData?.purchases?.purchased_consultation
                && !this.positionData?.purchases?.paid_consultation
        },
        purchaseInfoText() {
            if (this.showPurchasePositionButton) {
                return this.$t('positionBuyInfo')
            } else if (this.showPurchaseChatButton) {
                return this.$t('chatBuyInfo')
            } else {
                return ''
            }
        },
        showInputError() {
            return key => {
                const keyValuePath = 'formData.' + key
                return this.validationErrorHandler(this.v$, keyValuePath)
            }
        },
    },
    validations() {
        return {
            formData: {
                acceptConsent: {
                    sameAs(val) {
                        return val
                    }
                },
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.component-purchase-position-button::v-deep(.purchase-position-button) {
    .purchase-info {
        text-align: center;
        color: $text--gray;
    }

    .purchase-description {
        white-space: pre-line;
    }

    .form-check-label {
        font-style: italic;
        font-size: 0.85rem;
    }
}
</style>