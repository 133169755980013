import { mapGetters } from "vuex";

export default {
    methods: {
        /**
         * GET parameters for positions - for filter or create position form
         * @param {string} type - type of parameters: subject_school | subject_university | industry | ...
         */
        async axiosGetPositionParameters(type = 'all') {
            return this.$axios.get(this.$config.API_URL_V1 + `position/parameters/${type}`, this.$config.HEADERS)
        },
        /**
         * GET all video positions for a logged in or non logged in user
         * @param {string} type - logged | not-logged | my-offered-positions | my-buy-positions
         * @param {array} bodyParams
         */
        async axiosGetPositionList(type, bodyParams = []) {
            const urlBodyParams = await this.createURLBodyParams(bodyParams)
            return this.$axios.get(this.$config.API_URL_V1 + `position/${type}/index${urlBodyParams}`, this.$config.HEADERS)
        },
        /**
         * GET position detail by slug
         * @param {string} slug - slug position
         */
        async axiosGetPositionDetail(slug) {
            const cacheBuster = new Date().getTime();
            return this.$axios.get(this.$config.API_URL_V1 + `position/${this.checkUserLoggedIn}/${slug}?timestamp=${cacheBuster}`, this.$config.HEADERS)
        },
        /**
         * POST create new position
         * @param {object} postData - position data
         * @param {function} progressCallback
         */
        async axiosPostPositionCreate(postData, progressCallback) {
            let config = this.$config.FD_HEADERS;
            config['onUploadProgress'] = progressEvent => {
                // the total length of the data to be sent
                const totalLength = progressEvent.total;
                // the amount of data sent
                const uploadedBytes = progressEvent.loaded;
                // Calculate percentage progress
                const percentCompleted = Math.round((uploadedBytes / totalLength) * 100);

                // if a callback function is provided, call it with percentCompleted
                if (progressCallback) progressCallback(percentCompleted)
            };

            return await this.$axios.post(this.$config.API_URL_V1 + 'position', postData, config);
        },
        /**
         * POST update position
         * @param {string} positionId
         * @param {object} postData - position data
         * @param {function} progressCallback
         */
        async axiosPostPositionUpdate(positionId, postData, progressCallback) {
            let config = this.$config.FD_HEADERS;
            config['onUploadProgress'] = progressEvent => {
                // the total length of the data to be sent
                const totalLength = progressEvent.total;
                // the amount of data sent
                const uploadedBytes = progressEvent.loaded;
                // Calculate percentage progress
                const percentCompleted = Math.round((uploadedBytes / totalLength) * 100);

                // if a callback function is provided, call it with percentCompleted
                if (progressCallback) progressCallback(percentCompleted)
            };

            return await this.$axios.post(this.$config.API_URL_V1 + `position/${positionId}`, postData, config);
        },
        /**
         * POST video note
         * @param {string|number} positionId
         * @param {object} postData - note data
         */
        async axiosPostVideoNote(positionId, postData) {
            return await this.$axios.post(this.$config.API_URL_V1 + `note/${positionId}`, postData, this.$config.HEADERS);
        },
        /**
         * POST position purchase product
         * @param {object} postData - purchase data, example: { position_id: 1, model_type: 'message' | 'position' }
         */
        async axiosPostPurchaseProduct(postData) {
            return await this.$axios.post(this.$config.API_URL_V1 + 'order', postData, this.$config.HEADERS);
        },
        /**
         * GET position generate payment gateway link
         */
        async axiosGetGeneratePaymentGatewayLink(orderId) {
            return await this.$axios.get(this.$config.API_URL_V1 + `order/${orderId}/renew-payment`, this.$config.HEADERS);
        },
        /**
         * POST position rating
         * @param {object} postData - rating data, example: { position_id: 1, rating: 2 }
         */
        async axiosPostPositionRate(postData) {
            return await this.$axios.post(this.$config.API_URL_V1 + 'review/store', postData, this.$config.HEADERS);
        },
    },
    computed: {
        ...mapGetters({
            checkUserTokenExist: "user/checkUserTokenExist",
        }),
        /**
         * When the user is logged in, return 'logged' else return  'not-logged'
         * @return {string}
         */
        checkUserLoggedIn() {
            return this.checkUserTokenExist ? 'logged' : 'not-logged'
        }
    }
}