export default {
    methods: {
        /**
         * The function returns metadata about the video such as resolution or duration
         * @return {object} example:
         * {
         *     "resolution": {
         *         "width": 964,
         *         "height": 720
         *     },
         *     "duration": 48.715465
         * }
         */
        detectVideoMetaData(file) {
            return new Promise(resolve => {
                const fileUrl = URL.createObjectURL(file);
                let videoEl = document.createElement('video');
                videoEl.crossOrigin = 'anonymous';

                videoEl.addEventListener('loadedmetadata', async () => {
                    const width = videoEl.videoWidth;
                    const height = videoEl.videoHeight;

                    // Detect aspect ratio
                    const aspectRatio = width / height === 16 / 9;

                    // Wait for video to be fully loaded
                    // await new Promise(resolve => videoEl.addEventListener('loadeddata', resolve));

                    resolve({
                        resolution: {
                            width: width,
                            height: height,
                            aspectRatio: aspectRatio
                        },
                        duration: videoEl.duration
                    });
                });

                // Handle error during video loading
                videoEl.addEventListener('error', () => {
                    console.error('Error loading video');
                    resolve(null);
                });

                videoEl.src = fileUrl;
            });
        },
        /**
         * GET position points
         */
        async axiosGetPositionQuestions() {
            return this.$axios.get(this.$config.API_URL_V1 + `position/questions`, this.$config.HEADERS)
        },
        async axiosPostUpdateQuestionVideo(positionId, postData, progressCallback) {
            let config = this.$config.FD_HEADERS;
            config['onUploadProgress'] = progressEvent => {
                // the total length of the data to be sent
                const totalLength = progressEvent.total;
                // the amount of data sent
                const uploadedBytes = progressEvent.loaded;
                // Calculate percentage progress
                const percentCompleted = Math.round((uploadedBytes / totalLength) * 100);

                // if a callback function is provided, call it with percentCompleted
                if (progressCallback) progressCallback(percentCompleted)
            };

            return await this.$axios.post(this.$config.API_URL_V1 + `position/videos/${positionId}`, postData, config);
        }
    },
    computed: {
        /**
         * Convert time in seconds to specific format
         */
        secondsFormat() {
            return (secs, format = 'HH:mm:ss.SS') => {
                const milliseconds = Number(secs) * 1000
                return this.$moment.utc(milliseconds).format(format)
            }
        }
    }
}