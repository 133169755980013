<template>
    <div class="component-the-footer">
        <div class="the-footer container">
            <div class="content-wrapper">
                <div class="logo-wrapper">
                    <img class="logo" :src="logoFooter" alt="zealy logo" />
                </div>
                <div class="info-wrapper">
                    <p class="description">{{ getWebSettingsByKey('info_text') }}</p>

                    <h2 class="links-title">{{ $t('footer.links') }}</h2>
                    <div class="links-wrapper">
                        <div class="link" @click="redirectTo('vop')">
                            <span class="dot"></span>
                            {{ $t('footer.vop') }}
                        </div>
                        <div class="link" @click="redirectTo('vop_professional')">
                            <span class="dot"></span>
                            {{ $t('footer.complaintConditions') }}
                        </div>
                        <div class="link" @click="redirectTo('gdpr')">
                            <span class="dot"></span>
                            {{ $t('footer.gdpr') }}
                        </div>
                        <div class="link" @click="redirectTo('consent')">
                            <span class="dot"></span>
                            {{ $t('footer.consent') }}
                        </div>
                    </div>
                </div>
                <div class="support-wrapper">
                    <h2 class="support-title">{{ $t('footer.problem') }}</h2>

                    <div class="support-button support-button--phone" @click="handleOpen('tel', getWebSettingsByKey('support_phone'))">
                        <img class="support-button__icon" :src="phone" alt="phone icon" />
                        <span class="support-button__value">
                            {{ getWebSettingsByKey('support_phone') }}
                        </span>
                    </div>

                    <div class="support-button support-button--email" @click="handleOpen('mailto', getWebSettingsByKey('support_email'))">
                        <img class="support-button__icon" :src="mail" alt="mail icon" />
                        <span class="support-button__value">
                            {{ getWebSettingsByKey('support_email') }}
                        </span>
                    </div>
                </div>
            </div>

            <div class="author-wrapper text-center">
                {{ $t('footer.author') }}
                <a href="https://wame.sk/" target="_blank">
                    <img class="logo-wame" :src="logoWame" alt="logo WAME" />
                </a>
            </div>

            <div class="web-version text-center">{{ $config.webVersion }}</div>
        </div>
    </div>
</template>

<script>
import logoFooter from "../../assets/logo/logoFooterWhite.svg"
import logoWame from "../../assets/logo/wame.png"
import phone from "../../assets/icons/phone.svg"
import mail from "../../assets/icons/mail.svg"
import { mapGetters } from "vuex";

export default {
    name: "TheFooter",
    data() {
        return {
            logoFooter, logoWame, phone, mail,
        };
    },
    methods: {
        redirectTo(type) {
            window.open(this.getWebSettingsByKey(type), '_blank')
        },
        handleOpen(type, value) {
            const anchor = document.createElement('a');
            anchor.href = type + ':' + value
            anchor.click()
        }
    },
    computed: {
        ...mapGetters({
            getWebSettingsByKey: 'web/getWebSettingsByKey',
        })
    }
};
</script>

<style lang="scss" scoped>
.component-the-footer {
    background: transparent linear-gradient(77deg, #26415E 0%, #679BD4 100%) 0 0 no-repeat padding-box;
}

.component-the-footer::v-deep(.the-footer) {
    padding-top: 20px;
    padding-bottom: 20px;

    .content-wrapper {
        display: flex;
        justify-content: space-between;
        gap: 65px;
        margin: 40px 0;
    }

    .logo-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        .logo {
            width: 200px;
        }
    }

    .info-wrapper {
        .description {
            font-size: 14px;
            color: $white;
            margin-bottom: 2em;
            white-space: pre-line;
        }

        .links-title {
            margin-bottom: 1em;
            color: $white;
            font-weight: 700;
        }

        .links-wrapper {
            display: flex;
            gap: 40px;

            .link {
                display: flex;
                align-items: center;
                gap: 10px;
                color: $white;
                cursor: pointer;

                .dot {
                    display: block;
                    width: 5px;
                    height: 5px;
                    aspect-ratio: 1;
                    border-radius: 50%;
                    background: $secondary;
                }
            }
        }
    }

    .support-wrapper {
        margin-top: auto;
        color: $white;
        min-width: 250px;

        .support-title {
            text-align: right;
            font-weight: 700;
            line-height: 35px;
            font-size: 24px;
        }

        .support-button {
            margin-top: 10px;
            padding: 10px 15px;
            max-width: 400px;

            text-align: center;
            border-radius: 3px;
            cursor: pointer;
            transition: all 0.2s ease-in;

            &__icon {
                margin-right: 10px;
            }

            &__value {
                //
            }

            &--phone {
                border: 2px solid $white;

                &:hover {
                    background: rgba($white, 0.1);
                }
            }

            &--email {
                background: $secondary;
                border: 2px solid $secondary;

                &:hover {
                    border: 2px solid darken($secondary, 5%);
                    background: darken($secondary, 5%);
                }
            }
        }
    }

    .author-wrapper {
        padding-top: 20px;
        color: $white;
        border-top: 1px solid $white;

        .logo-wame {
            width: 90px;
        }
    }

    .web-version {
        color: $white;
    }

    @media (max-width: 1200px) {
        .info-wrapper {
            .links-wrapper {
                gap: 30px;
            }
        }
    }

    @media (max-width: 1199px) {
        .content-wrapper {
            display: flex;
            flex-direction: column;
            row-gap: 30px;
            margin: 20px 0;
        }

        .logo-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .info-wrapper {
            .description {
                margin-bottom: 2em;
            }

            .links-wrapper {
                flex-direction: column;
                row-gap: 10px;
            }
        }

        .support-wrapper {
            .support-title {
                text-align: left;
            }
        }
    }

    @media (max-width: 576px) {
        .support-button {
            width: 100%;
            max-width: unset;
        }
    }
}
</style>