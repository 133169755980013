<template>
    <div class="page page-position-detail">
        <div class="page-container container">

            <!-- SECTION EDITOR INFO & EDITOR PROFILE DATA-->
            <section class="editor-info-container" v-if="positionData">
                <div class="editor-info-header">
                    <div class="editor-info-header__photo">
                        <div class="editor-photo">
                            <img
                                class="photo"
                                :src="positionData?.editor?.avatar"
                                width="300"
                                alt="editor profile photo"
                            />
                        </div>
                    </div>

                    <div class="editor-info-header__about">
                        <div class="editor-name-wrapper">
                            <h1 class="editor-name">{{ positionData?.editor?.first_name }}</h1>

                            <PurchasePositionButton
                                class="purchase-button--name"
                                v-if="!isMyPosition && checkUserTokenExist"
                                :position-data="positionData"
                                :loading-state="loaders.purchaseProduct"
                                @purchase-product="purchaseProduct"
                            />
                        </div>

                        <div class="position-info">
                            <div class="position-info__title label">{{ $t('extendedProfile.actualPositionName.label') }}</div>
                            <div class="position-info__value">{{ positionData?.editor?.actual_position || '-' }}</div>
                        </div>

                        <div class="position-info">
                            <div class="position-info__title label">{{ $t('extendedProfile.aboutMe.label') }}</div>
                            <div class="position-info__value">{{ positionData?.editor?.about || '-' }}</div>
                        </div>
                    </div>
                </div>

                <div class="editor-profile-data row row-items gx-5">
                    <div class="col col-item col-12">
                        <h2 class="work-experience-title">{{ $t('editorWorkExperience') }}</h2>
                        <p class="work-experience-description">{{ $t('threeImportantPositions') }}</p>
                    </div>
                    <div class="col col-item col-12 col-lg-6 border-right">
                        <div class="previous-position">
                            <div class="position-info">
                                <div class="position-info__title label">{{ $t('extendedProfile.positionName.label') }} 1</div>
                                <div class="position-info__value">{{ positionData?.editor?.prev_position1 || '-' }}</div>
                            </div>

                            <div class="position-info">
                                <div class="position-info__title label">{{ $t('extendedProfile.positionDuration.label') }}</div>
                                <div class="position-info__value">{{ positionData?.editor?.prev_position1_duration || '-' }}</div>
                            </div>
                        </div>

                        <div class="previous-position">
                            <div class="position-info">
                                <div class="position-info__title label">{{ $t('extendedProfile.positionName.label') }} 2</div>
                                <div class="position-info__value">{{ positionData?.editor?.prev_position2 || '-' }}</div>
                            </div>

                            <div class="position-info">
                                <div class="position-info__title label">{{ $t('extendedProfile.positionDuration.label') }}</div>
                                <div class="position-info__value">{{ positionData?.editor?.prev_position2_duration || '-' }}</div>
                            </div>
                        </div>

                        <div class="previous-position">
                            <div class="position-info">
                                <div class="position-info__title label">{{ $t('extendedProfile.positionName.label') }} 3</div>
                                <div class="position-info__value">{{ positionData?.editor?.prev_position3 || '-' }}</div>
                            </div>

                            <div class="position-info">
                                <div class="position-info__title label">{{ $t('extendedProfile.positionDuration.label') }}</div>
                                <div class="position-info__value">{{ positionData?.editor?.prev_position3_duration || '-' }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col col-item col-12 col-lg-6">
                        <div class="position-info">
                            <div class="position-info__title label">{{ $t('extendedProfile.highSchool.label') }}</div>
                            <div class="position-info__value">{{ positionData?.editor?.high_school || '-' }}</div>
                        </div>

                        <div class="position-info">
                            <div class="position-info__title label">{{ $t('extendedProfile.university.label') }}</div>
                            <div class="position-info__value">{{ positionData?.editor?.university || '-' }}</div>
                        </div>

                        <div class="position-info">
                            <div class="position-info__title label">{{ $t('extendedProfile.languageSkills.label') }}</div>
                            <div class="position-info__value">{{ positionData?.editor?.language_skills || '-' }}</div>
                        </div>

                        <div class="position-info">
                            <div class="position-info__title label">{{ $t('extendedProfile.certificates.label') }}</div>
                            <div class="position-info__value" v-html="positionData?.editor?.certifications || '-'"></div>
                        </div>
                    </div>
                </div>
            </section>

            <hr class="hr-line" />

            <!-- SECTION POSITION INFORMATION -->
            <section class="position-detail-container row row-items gx-5" v-if="positionData">
                <div class="col col-item col-12">
                    <h2 class="work-experience-title">{{ $t('positionInfoRepresent') }}</h2>
                </div>

                <div class="col col-item col-12">
                    <div class="position-info mb-4">
                        <div class="position-info__title label">{{ $t('position.positionTitle.label') }}</div>
                        <div class="position-info__value">{{ positionData?.title }}</div>
                    </div>

                    <div class="position-info mb-4">
                        <div class="position-info__title label">{{ $t('position.positionDescription.label') }}</div>
                        <div class="position-info__value">{{ positionData?.description }}</div>
                    </div>
                </div>

                <div class="col col-item col-12 col-lg-6">
                    <div class="position-info">
                        <div class="position-info__title label">{{ $t('position.industrialSector.label') }}</div>
                        <div class="position-info__value">{{ positionData?.industry_sector?.title }}</div>
                    </div>

                    <div class="position-info">
                        <div class="position-info__title label">{{ $t('position.educationLevel.label') }}</div>
                        <div class="position-info__value">{{ positionData?.required_education?.title }}</div>
                    </div>

                    <div class="position-info">
                        <div class="position-info__title label">{{ $t('position.highSchoolSubjects.label') }}</div>
                        <div class="position-info__value">
                            <span class="value-item" v-for="(value, index) in positionData?.subject_high_school" :key="value.id">
                                {{ value.title }}<span v-if="index + 1 < positionData?.subject_high_school?.length">, </span>
                            </span>
                        </div>
                    </div>

                    <div class="position-info">
                        <div class="position-info__title label">{{ $t('position.universitySubjects.label') }}</div>
                        <div class="position-info__value">
                            <span class="value-item" v-for="(value, index) in positionData?.subject_university" :key="value.id">
                                {{ value.title }}<span v-if="index + 1 < positionData?.subject_university?.length">, </span>
                            </span>
                        </div>
                    </div>

                    <div class="position-info">
                        <div class="position-info__title label">{{ $t('position.hobby.label') }}</div>
                        <div class="position-info__value">
                    <span class="value-item" v-for="(value, index) in positionData?.hobby" :key="value.id">
                        {{ value.title }}<span v-if="index + 1 < positionData?.hobby?.length">, </span>
                    </span>
                        </div>
                    </div>

                    <div class="position-info">
                        <div class="position-info__title label">{{ $t('position.languagesPreferred.label') }}</div>
                        <div class="position-info__value">
                            <span class="value-item" v-for="(value, index) in positionData?.prefer_language" :key="value.id">
                                {{ value.title }}<span v-if="index + 1 < positionData?.prefer_language?.length">, </span>
                            </span>
                        </div>
                    </div>

                    <div class="position-info">
                        <div class="position-info__title label">{{ $t('position.homeworkPossibility.label') }}</div>
                        <div class="position-info__value">{{ positionData?.homework?.title }}</div>
                    </div>

                    <div class="position-info">
                        <div class="position-info__title label">{{ $t('position.positionPlace.label') }}</div>
                        <div class="position-info__value">
                            <span class="value-item" v-for="(value, index) in positionData?.place_execution" :key="value.id">
                                {{ value.title }}<span v-if="index + 1 < positionData?.place_execution?.length">, </span>
                            </span>
                        </div>
                    </div>

                    <!--                    <div class="position-info">-->
                    <!--                        <div class="position-info__title label">{{ $t('position.universityFields.label') }}</div>-->
                    <!--                        <div class="position-info__value">{{ positionData?.university_department?.title }}</div>-->
                    <!--                    </div>-->
                </div>

                <div class="col col-item col-12 col-lg-6">
                    <div class="position-info">
                        <div class="position-info__title label">{{ $t('position.positionDistrict.label') }}</div>
                        <div class="position-info__value">
                            <span class="value-item" v-for="(value, index) in positionData?.district" :key="value.id">
                                {{ value.title }}<span v-if="index + 1 < positionData?.district?.length">, </span>
                            </span>
                        </div>
                    </div>

                    <div class="position-info">
                        <div class="position-info__title label">{{ $t('position.positionPotential.label') }}</div>
                        <div class="position-info__value">{{ positionData?.employment_potential?.title }}</div>
                    </div>

                    <div class="position-info">
                        <div class="position-info__title label">{{ $t('position.employeesCount.label') }}</div>
                        <div class="position-info__value">{{ positionData?.people_count?.title }}</div>
                    </div>

                    <div class="position-info">
                        <div class="position-info__title label">{{ $t('position.powerRatio.label') }}</div>
                        <div class="position-info__value">{{ positionData?.physical_proportion?.title }}</div>
                    </div>

                    <div class="position-info">
                        <div class="position-info__title label">{{ $t('position.expectedSalary.label') }}</div>
                        <div class="position-info__value">{{ positionData?.salary?.title }}</div>
                    </div>

                    <div class="position-info">
                        <div class="position-info__title label">{{ $t('position.careerGrowthPossibility.label') }}</div>
                        <div class="position-info__value">{{ positionData?.career_grow?.title }}</div>
                    </div>

                    <div class="position-info">
                        <div class="position-info__title label">{{ $t('position.workplaceVideo.label') }}</div>
                        <div class="position-info__value">{{ positionData?.video_in_workplace?.title }}</div>
                    </div>
                </div>
            </section>

            <hr class="hr-line" />

            <div class="row gx-5">
                <div class="col col-12 col-lg-6 mt-2 mb-2">
                    <h2>{{ $t('positionEvaluation') }}</h2>
                    <p>{{ $t('positionEvaluationSubtitle') }}</p>

                    <PositionRating
                        class="mt-2"
                        :star-size="20"
                        :increment="0.5"
                        :rating="positionData?.position_rating?.average_rating"
                        :rating-count="positionData?.position_rating?.rating_count"
                    />
                </div>

                <div class="col col-12 col-lg-6 mt-2 mb-2" v-if="checkUserTokenExist && !isMyPosition">
                    <h2>{{ $t('positionRate') }}</h2>
                    <p>{{ $t('positionRateSubtitle') }}</p>

                    <PositionRating
                        class="mt-2"
                        :star-size="20"
                        :read-only="!positionData?.position_rating?.can_review"
                        :rating="positionData?.position_rating?.my_rating"
                        :show-rating-count="false"
                        @enter-rating="submitPositionRating"
                    />
                </div>
            </div>

            <hr class="hr-line" />

            <div class="purchase-product-wrapper" v-if="checkUserTokenExist && !isMyPosition">
                <PurchasePositionButton
                    class="purchase-button--name"
                    v-if="!isMyPosition && checkUserTokenExist && (!isPositionPurchased || !isChatPurchased)"
                    :show-buy-info="true"
                    :position-data="positionData"
                    :loading-state="loaders.purchaseProduct"
                    @purchase-product="purchaseProduct"
                />
            </div>

            <!-- SECTION POSITION VIDEO -->
            <div class="position-video-list">
                <div
                    class="position-video"
                    v-for="(video, index) in positionData.videos" :key="video.id"
                >
                    <div class="row row-items">
                        <div class="col-item col-12">
                            <h3 class="position-video__title">{{ index + 1 }}. {{ video.question?.title }}</h3>
                            <p class="position-video__description" v-html="video.question?.description"></p>
                        </div>

                        <div class="position-video__preview col-item col-12 col-lg-7">
                            <video
                                class="video-preview"
                                v-if="video.original && (isPositionPurchased || isMyPosition)"
                                width="250"
                                controls
                                controlsList="nodownload"
                                oncontextmenu="return false;"
                            >
                                <source :src="video.original" type="video/mp4" />
                            </video>
                            <img
                                v-else
                                class="video-thumbnail"
                                :src="video.cover"
                                alt="video preview"
                            />
                        </div>

                        <div
                            class="position-video__note col-item col-12 col-lg-5"
                            v-if="checkUserTokenExist && (isPositionPurchased || video.note)"
                        >
                            <TheInput
                                input-mode="textarea"
                                :input-id="'inputNote-' + video.id"
                                v-model:value="video.note"
                                :is-required="false"
                                :is-disabled="!positionData?.purchases?.paid_position"
                                :input-label="$tc('position.note.label', 1)"
                                :input-placeholder="$t('position.note.placeholder')"
                            />

                            <div
                                class="save-note-button"
                                :class="{ 'disable-element': !positionData?.purchases?.paid_position }"
                                v-if="isPositionPurchased"
                                @click.prevent="saveNote(video.id, video.note)"
                            >
                                {{ $t('position.note.save') }}
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="purchase-product-wrapper" v-if="checkUserTokenExist && !isMyPosition">
                <PurchasePositionButton
                    class="purchase-button--name"
                    v-if="!isMyPosition && checkUserTokenExist && (!isPositionPurchased || !isChatPurchased)"
                    :show-buy-info="true"
                    :position-data="positionData"
                    :loading-state="loaders.purchaseProduct"
                    @purchase-product="purchaseProduct"
                />
            </div>

            <ChatCommunication
                v-if="
                    isMyPosition
                    || positionData?.purchases?.show_chat
                    || (checkUserTokenExist && positionSlug && positionData.purchases?.paid_consultation)"
                :position-slug="positionSlug"
            />
        </div>
    </div>
</template>

<script>
import positionService from "../../functions/services/positionService";
import ChatCommunication from "../../components/chat/ChatCommunication.vue";
import TheInput from "../../components/elements/TheInput.vue";
import PurchasePositionButton from "../../components/position/detail/PurchasePositionButton.vue";
import { mapGetters } from "vuex";
import PositionRating from "../../components/position/elements/PositionRating.vue";

export default {
    name: "PagePositionDetail",
    mixins: [ positionService ],
    components: {
        PositionRating,
        PurchasePositionButton,
        TheInput,
        ChatCommunication,
    },
    metaInfo() {
        return {
            title: this.positionData?.title || 'Detail video profilu',
            meta: [
                { name: 'description', Name: 'description', content: this.positionData?.description || 'Popis video profilu' },
            ]
        }
    },
    data() {
        return {
            positionSlug: '',
            positionData: {},

            loaders: {
                saveNote: false,
                purchaseProduct: false,
            }
        };
    },
    mounted() {
        // set position slug
        this.positionSlug = this.$route?.params?.slug

        this.getPositionDetail()
    },
    methods: {
        /**
         * GET position detail
         */
        getPositionDetail() {
            this.axiosGetPositionDetail(this.positionSlug).then(res => {
                // console.log('getPositionDetail', res.data.data);
                this.positionData = res.data.data
            }, e => {
                if (e.response.status === 302) {
                    this.$root.$refs.ref_toast.showToast(e.response?.data?.message, 'toast-danger')
                    this.checkUserTokenExist
                        ? this.$router.replace({ name: 'PagePositionsOwned' })
                        : this.$router.replace({ name: 'PageHome' })
                }
            })
        },
        async saveNote(videoId, noteText) {
            // console.log('saveNote', videoId, noteText);
            this.loaders.saveNote = true

            let postData = {}
            postData['video_id'] = videoId
            postData['note'] = noteText

            await this.axiosPostVideoNote(this.positionSlug, postData).then(res => {
                // console.log('saveNote', res);
                this.$root.$refs.ref_toast.showToast(res.data?.message, 'toast-success')
            }, e => {
                const message = e?.response?.data?.message
                this.$root.$refs.ref_toast.showToast(message, 'toast-danger')
            }).finally(() => {
                this.loaders.saveNote = false
            })
        },
        purchaseProduct(productType) {
            // set order by order type - position or chat
            let orderIdByType = ''

            if (!this.positionData?.purchases?.paid_position) {
                // buy - position
                orderIdByType = this.positionData?.order?.id

                if (this.positionData?.order?.status?.id === "1") {
                    this.getPaymentGatewayLinkRedirect(orderIdByType)
                } else if (!orderIdByType || this.positionData?.order?.status === "3") {
                    this.createOrder(productType)
                }
            } else {
                // buy - chat
                orderIdByType = this.positionData?.order_chat?.id

                if (this.positionData?.order_chat?.status?.id === "1") {
                    this.getPaymentGatewayLinkRedirect(orderIdByType)
                } else if (!orderIdByType || this.positionData?.order?.status === "3") {
                    this.createOrder(productType)
                }
            }
        },
        createOrder(productType) {
            let postData = {}
            postData['position_id'] = this.positionData.id
            postData['model_type'] = productType
            postData['purchase_consent'] = true

            this.loaders.purchaseProduct = true
            this.axiosPostPurchaseProduct(postData).then(res => {
                // console.log('axiosPostPurchaseProduct', res);
                const data = res.data.data

                this.$nextTick(() => {
                    // redirect to payment gateway
                    window.open(data.invoice?.payment_links, '_self')

                    // this.$router.push({ name: 'PageThankYou', params: { order_id: data.id || 'no-order-id' } })
                    // this.$root.$refs.ref_toast.showToast(res.data.message, 'toast-success')
                })
            }, e => {
                // console.error(e);
                const message = e?.response?.data?.message
                this.$root.$refs.ref_toast.showToast(message, 'toast-danger')
            }).finally(() => {
                setTimeout(() => {
                    this.loaders.purchaseProduct = false
                }, 1000)
            })
        },
        getPaymentGatewayLinkRedirect(orderId) {
            // order received but not paid
            this.loaders.purchaseProduct = true
            this.axiosGetGeneratePaymentGatewayLink(orderId).then(res => {
                // console.log('axiosPostPurchaseProduct', res);
                const data = res.data.data

                this.$nextTick(() => {
                    // redirect to payment gateway
                    window.open(data?.invoice?.payment_links, '_self')
                })
            }, e => {
                // console.error(e);
                const message = e?.response?.data?.message
                this.$root.$refs.ref_toast.showToast(message, 'toast-danger')
            }).finally(() => {
                this.loaders.purchaseProduct = false
            })
        },
        submitPositionRating(rating) {
            let postData = {}
            postData['position_id'] = this.positionData.id
            postData['rating'] = rating

            this.axiosPostPositionRate(postData).then(res => {
                // console.log('axiosPostPositionRate', res);
                const data = res.data.data

                // prevent evaluation
                this.positionData.position_rating.can_review = false

                // set new rating
                this.positionData.position_rating.average_rating = data.average_rating
                this.positionData.position_rating.rating_count = data.rating_count

                this.$root.$refs.ref_toast.showToast(res.data?.message, 'toast-success')
            }, e => {
                const message = e?.response?.data?.message
                this.$root.$refs.ref_toast.showToast(message, 'toast-danger')
            })
        }
    },
    computed: {
        ...mapGetters({
            checkUserTokenExist: 'user/checkUserTokenExist',
            getUserDataByKey: 'user/getUserDataByKey',
        }),
        /**
         * Returns true if the position is purchased
         * @returns {boolean}
         */
        isPositionPurchased() {
            return !!this.positionData?.purchases?.purchased_position
        },
        /**
         * Returns true if communication with the editor is purchased
         * @returns {boolean}
         */
        isChatPurchased() {
            return !!this.positionData?.purchases?.purchased_consultation
        },
        isMyPosition() {
            return this.positionData.editor?.id === this.getUserDataByKey('id')
        }
    }
};
</script>

<style lang="scss" scoped>
.page-position-detail::v-deep(.page-container) {
    .hr-line {
        margin: 2rem 0;
    }

    .position-info {
        margin-bottom: 15px;

        &__title {
            white-space: nowrap;

            &.label {
                margin-bottom: 0;
            }
        }

        &__value {
            //
        }
    }

    .editor-info-container {
        .editor-info-header {
            display: flex;
            gap: 40px;
            margin-bottom: 20px;

            &__photo {
                .editor-photo {
                    width: 300px;
                    height: 300px;
                    aspect-ratio: 1;
                    border-radius: 6px;

                    .photo {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: inherit;
                    }
                }
            }

            &__about {
                width: 100%;

                .editor-name-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 20px;
                }
            }
        }

        .editor-profile-data {
            .work-experience-title {
                //
            }

            .work-experience-description {
                margin-bottom: 15px;
            }

            .previous-position {
                display: flex;
                gap: 20px;

                .position-info:first-of-type {
                    width: 100%;
                }
            }

            .border-right {
                //border-right: 1px solid $borderColor;
            }
        }
    }

    .position-detail-container {
        margin-top: 20px;

        .work-experience-title {
            margin-bottom: 15px;
        }
    }

    .position-video-list {
        margin: 50px 0;

        .position-video {
            margin-bottom: 50px;

            &__title {
                margin-bottom: 10px;
            }

            &__description {
                white-space: break-spaces;
                margin-bottom: 15px;
            }

            &__preview {
                border-radius: 6px;

                .video-preview {
                    width: 100%;
                    max-height: 450px;
                    border-radius: inherit;
                }

                .video-thumbnail {
                    width: 100%;
                    max-height: 450px;
                    object-fit: contain;
                    border-radius: inherit;
                }
            }

            &__note {
                .input-container {
                    margin-bottom: 0;
                }

                .save-note-button {
                    text-align: right;
                    font-size: 14px;
                    cursor: pointer;
                    font-weight: 700;
                    color: $tertiary;
                }
            }
        }
    }

    .purchase-product-wrapper {
        margin: 40px 0;
    }

    @media (min-width: 1199px) {
        .editor-info-container {
            .editor-profile-data {
                .border-right {
                    border-right: 1px solid $borderColor;
                }
            }
        }
    }

    @media (min-width: 992px) {
        .editor-info-container {
            .editor-profile-data {
                .col-item {
                    .position-info:last-of-type {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    @media (max-width: 991px) {
        .editor-info-container {

            .editor-info-header {
                flex-direction: column;
                row-gap: 20px;

                &__photo {
                    display: flex;
                    justify-content: center;

                    .editor-photo {
                        width: 100%;
                        height: 100%;
                        max-width: 300px;
                        max-height: 300px;
                    }
                }

                &__about {
                    .editor-name-wrapper {
                        flex-direction: column;
                        gap: 10px;
                        width: 100%;

                        .editor-name {
                            text-align: center
                        }
                    }
                }
            }

            .editor-profile-data {
                .position-info {
                    margin-bottom: 15px;
                }

                .border-right {
                    border-right: 0;
                }
            }
        }
    }

    @media (max-width: 575px) {
        .editor-info-container {
            .editor-profile-data {
                .previous-position {
                    flex-direction: column;
                    row-gap: 0;
                }
            }
        }
    }
}
</style>