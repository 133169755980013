<template>
    <div
        class="component-login-modal modal fade"
        id="loginModal"
        tabindex="-1"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content">
                <div class="modal-body">
                    <span class="close-modal-wrapper" data-bs-dismiss="modal">
                        <WebIcon icon-name="close" icon-color="white" />
                    </span>

                    <section class="section section-left">
                        <h2 class="login-title">{{ $t('login') }}</h2>

                        <form class="form-container" id="loginForm" autocomplete="on" @keydown.enter="submitForm">
                            <TheInput
                                input-id="inputEmailLogin"
                                input-name="email"
                                input-type="email"
                                v-model:value="formData.email"
                                :is-required="true"
                                :input-label="$t('email.label')"
                                :input-placeholder="$t('email.placeholder')"
                                :autocomplete="'email'"
                                :error-text="showInputError('formData.email')"
                                @start-validate="startPartialValidation('formData.email')"
                            />

                            <TheInput
                                input-id="inputPasswordLogin"
                                input-name="password"
                                input-type="password"
                                v-model:value="formData.password"
                                :is-required="true"
                                :input-label="$t('password.label')"
                                :input-placeholder="$t('password.placeholder')"
                                :autocomplete="'current-password'"
                                :error-text="showInputError('formData.password')"
                                @start-validate="startPartialValidation('formData.password')"
                            />

                            <div
                                class="forgotten-password"
                                data-bs-target="#forgottenPasswordModal"
                                data-bs-toggle="modal"
                            >
                                {{ $t('forgotPassword.title') }}
                            </div>

                            <MainButton
                                btn-type="submit"
                                color-mode="secondary"
                                :btn-text="$t('signUp')"
                                :is-loading="loaders.submitForm"
                                @click.prevent="submitForm"
                            />
                        </form>

                        <div
                            class="dont-have-account-wrapper"
                            data-bs-target="#registrationModal"
                            data-bs-toggle="modal"
                        >
                            {{ $t('alreadyRegistered.dontHaveAccount') }}
                            <span class="redirect-reg">{{ $t('alreadyRegistered.register') }}</span>
                        </div>
                    </section>

                    <section class="section section-right">
                        <img
                            class="image"
                            :src="loginPreview"
                            alt=" "
                        />
                    </section>
                </div>
            </div>
        </div>
    </div>

    <div
        class="component-forgotten-password-modal modal fade"
        id="forgottenPasswordModal"
        tabindex="-1"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <span class="close-modal-wrapper" data-bs-dismiss="modal">
                        <WebIcon icon-name="close" icon-color="white" />
                    </span>
                    <h2 class="forgotten-password-title">{{ $t('forgotPassword.forgottenPassword') }}</h2>
                    <p class="description">{{ $t('forgotPassword.description') }}</p>

                    <form class="form-container" id="forgottenPasswordForm" autocomplete="on">
                        <TheInput
                            input-id="inputEmailForgottenPassword"
                            input-name="email"
                            input-type="email"
                            v-model:value="forgottenPasswordData.email"
                            :is-required="true"
                            :input-label="$t('email.label')"
                            :input-placeholder="$t('email.placeholder')"
                            :autocomplete="'email'"
                            :error-text="showInputError('forgottenPasswordData.email')"
                            @start-validate="startPartialValidation('forgottenPasswordData.email')"
                        />

                        <MainButton
                            btn-type="submit"
                            :btn-text="$t('forgotPassword.resetPassword')"
                            :is-loading="loaders.resetPassword"
                            @click.prevent="resetPassword"
                        />
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, email, minLength } from '@vuelidate/validators'
import validation from "../../functions/validation";
import WebIcon from "../elements/WebIcon.vue";
import MainButton from "../elements/MainButton.vue";
import { mapActions } from "vuex";
import loginPreview from "../../assets/images/auth/loginPreview.jpg"
import TheInput from "../elements/TheInput.vue";

export default {
    name: "LoginModal",
    mixins: [ validation ],
    components: {
        TheInput,
        WebIcon,
        MainButton
    },
    setup() {
        return {
            v$: useVuelidate()
        }
    },
    data() {
        return {
            loginPreview,

            formData: {
                email: '',
                password: '',
            },
            forgottenPasswordData: {
                email: ''
            },
            errorText: {
                login: '',
                forgotPassword: ''
            },
            loaders: {
                submitForm: false,
                resetPassword: false,
            }
        };
    },
    mounted() {
        const modalLogin = document.querySelector('#loginModal')
        modalLogin.addEventListener('hidden.bs.modal', () => {
            this.closingModal()
        })

        const modalForgottenPassword = document.querySelector('#forgottenPasswordModal')
        modalForgottenPassword.addEventListener('hidden.bs.modal', () => {
            this.closingModal()
        })
    },
    methods: {
        ...mapActions({
            storeAuthData: 'user/storeAuthData',
            storeUserData: 'user/storeUserData',
        }),
        showModal(modalId) {
            const elementId = '#' + modalId
            const loginModal = window.bootstrap.Modal.getOrCreateInstance(elementId);
            loginModal.show()
        },
        /**
         * For close modal
         * @param {string} modalId
         * @example loginModal | forgottenPasswordModal
         */
        closeModal(modalId) {
            const elementId = '#' + modalId
            const loginModal = window.bootstrap.Modal.getOrCreateInstance(elementId);
            loginModal.hide()
        },
        /**
         * Running the function when closing the modal
         */
        closingModal() {
            // reset forms
            // document.querySelector('#loginForm').reset()
            // document.querySelector('#forgottenPasswordForm').reset()

            this.formData.email = ''
            this.formData.password = ''
            this.forgottenPasswordData.email = ''

            this.v$.$reset()
        },
        /**
         * After leaving the input, start the validation of the given value
         * @param {string} path - data path
         * @example 'formData.name'
         */
        startPartialValidation(path) {
            path.split('.').reduce((a, b) => this.v$[a][b].$touch())
        },
        async submitForm() {
            const isFormCorrect = await this.v$.formData.$validate()
            if (!isFormCorrect) return

            this.loaders.submitForm = true
            this.$axios.post(this.$config.API_URL_V1 + 'login', this.formData).then(res => {
                // console.log('submitForm login', res);
                const data = res.data.data

                // store data to vuex
                this.storeAuthData(data?.auth)
                this.storeUserData(data?.user)

                this.$root.$refs.ref_toast.showToast(res.data?.message, 'toast-success')
                this.closeModal('loginModal')
            }, e => {
                // console.error(e);
                const message = e?.response?.data?.message
                this.$root.$refs.ref_toast.showToast(message, 'toast-danger')
            }).finally(() => {
                this.loaders.submitForm = false
            })
        },
        async resetPassword() {
            const isFormCorrect = await this.v$.forgottenPasswordData.$validate()
            if (!isFormCorrect) return

            this.loaders.resetPassword = true
            this.$axios.post(this.$config.API_URL_V1 + 'password/reset/send', this.forgottenPasswordData).then(res => {
                // console.log('resetPassword', res);
                // const data = res.data.data

                this.$root.$refs.ref_toast.showToast(res.data?.message, 'toast-success')
                this.closeModal('forgottenPasswordModal')
            }, e => {
                // console.error(e);
                const message = e?.response?.data?.message
                this.$root.$refs.ref_toast.showToast(message, 'toast-danger')
            }).finally(() => {
                this.loaders.resetPassword = false
            })
        }
    },
    computed: {
        showInputError() {
            return keyPath => this.validationErrorHandler(this.v$, keyPath)
        }
    },
    validations() {
        return {
            formData: {
                email: {
                    required,
                    email
                },
                password: {
                    required,
                    minLength: minLength(8)
                }
            },
            forgottenPasswordData: {
                email: {
                    required,
                    email
                },
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.component-login-modal,
.component-forgotten-password-modal {
    .close-modal-wrapper {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 20px;
        right: 20px;

        opacity: 1;
        cursor: pointer;
        background: $primary;
        border-radius: 50%;
        z-index: 1;

        .web-icon-wrapper {
            width: 20px;
            height: 20px;
            aspect-ratio: 1;
            border-radius: 50%;
        }
    }
}

.component-login-modal::v-deep(.modal-dialog) {
    .modal-content {
        .modal-body {
            position: relative;
            display: flex;
            padding: 0;

            border-radius: inherit;

            .section {
                position: relative;
                box-sizing: border-box;

                &-left {
                    width: 50%;
                    padding: 40px 40px 33px;

                    .login-title {
                        margin-bottom: 35px;
                        font-size: 40px;
                        font-weight: 700;
                    }

                    .form-container {
                        .input-container-wrapper {
                            .input-container {
                                .input {
                                    border-color: $inputBorderColor;
                                }
                            }
                        }
                    }

                    .forgotten-password {
                        margin-bottom: 20px;

                        cursor: pointer;
                        font-size: .85rem;
                        font-weight: 600;
                        color: $secondary;
                        text-align: right;
                    }

                    .dont-have-account-wrapper {
                        margin-top: 15px;

                        cursor: pointer;
                        font-size: .85rem;
                        font-weight: 600;
                        text-align: center;

                        .redirect-reg {
                            color: $secondary;
                        }
                    }
                }

                &-right {
                    width: 50%;

                    .image {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 0 $modal-content-border-radius $modal-content-border-radius 0;
                    }
                }
            }
        }
    }

    @media (max-width: 576px) {
        .modal-content {
            .modal-body {
                .section {
                    padding: 25px 25px;

                }

                .login-title {
                    font-size: 30px;
                }
            }
        }
    }

    @media (max-width: 992px) {
        .modal-content {
            .modal-body {
                .section {
                    width: 100%;

                    &-right {
                        display: none;
                    }
                }
            }
        }
    }
}

.component-forgotten-password-modal::v-deep(.modal-dialog) {
    .forgotten-password-title {
        font-weight: 700;
    }

    .description {
        margin: 20px 0;
    }

    .form-container {
        .input-container-wrapper {
            .input-container {
                .label {
                    margin-bottom: 5px;
                }

                .input {
                    border-color: $inputBorderColor;
                }
            }
        }
    }

    .component-main-button {
        margin-top: 30px;
    }
}
</style>