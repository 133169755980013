const webMode = "PROD" // "DEV" | "PROD"
const webVersion = "1.0.0"

const devApi = {
    BASE_URL: "https://dev.zealy.sk",
    STORAGE_URL: "https://dev.zealy.sk/storage/",
    API_URL_V1: "https://dev.zealy.sk/api/v1/",
}

const prodApi = {
    BASE_URL: "https://admin.zealy.sk",
    STORAGE_URL: "https://admin.zealy.sk/storage/",
    API_URL_V1: "https://admin.zealy.sk/api/v1/",
}

let config = {
    webVersion: webVersion,
    HEADERS: {
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
        }
    },
    FD_HEADERS: {
        headers: {
            "Content-Type": "multipart/form-data",
            "Accept": "application/json"
        }
    }
}

if (webMode === "DEV") {
    config = {
        ...config,
        ...devApi
    }
} else {
    config = {
        ...config,
        ...prodApi
    }
}

export default config